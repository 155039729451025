import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/main.scss'
import locale from 'element-ui/lib/locale/lang/mn'

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})

new Vue({
  router,
  store,
  data: {
    fullscreenLoading: false,
    rootNotificationCount: 0,
    studentDetailDialogVariable: false,
    studentTransactionDialog: false,
    studentNoteDialog: false
  },
  render: h => h(App)
}).$mount('#app')
