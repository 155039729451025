<template>
    <el-container>
        <el-header class="profile-header textcenter">
            <span>Сүүлийн 30 хоногийн ирц</span>
        </el-header>
        <el-row>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <!-- <h5 class="m-l-25">Ээлж</h5> -->
            <el-select size="mini" class="m-l-20 m-t-20 m-b-20" v-model="eelj" placeholder="Ээлж" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-select size="mini" class="m-l-20 m-t-20  m-b-20" v-model="teacher_name" placeholder="Багш" clearable>
              <el-option v-for="item in optionsTeacher" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col> -->
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <!-- <h5 class="m-l-25">Багш</h5> -->
            <el-select size="mini" class="m-l-20 m-t-20  m-b-20" v-model="lesson_name" placeholder="Хичээл" clearable>
              <el-option v-for="item in optionsLesson" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
            <div class="m-l-20 m-t-20 m-b-20">
              <el-button size="mini" @click="viewAttendanceTeacherApp()" type="success">Хайх</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row class="m-b-100">
          <el-col class="ml-5">
            <el-table :data="studentList" class="text_size" >
              <el-table-column label="№" type="index" fixed="left"></el-table-column>
              <el-table-column label="Овог" prop="name" width="90"></el-table-column>
              <el-table-column label="Анги" prop="grade" width="90"></el-table-column>
              <el-table-column label="Сургууль" prop="school" width="95"></el-table-column>
              <el-table-column label="Тэмдэглэл" fixed="left">
                <template slot-scope="scope">
                  <el-button class="el-icon-chat-square" type="warning" @click="getStudentNote(scope.row)" size="mini"></el-button>
                </template>
              </el-table-column>
              <el-table-column label="Нэр" prop="last_name"  width="100"  fixed="left"></el-table-column>
              <el-table-column prop="teacher_name" label="Багш" width="100px"
              :filters="[{text: 'Болортуяа', value: 'Болортуяа'},
                          {text: 'Алтанзаяа', value: 'Алтанзаяа'},
                          {text: 'Хосбаяр', value: 'Хосбаяр'},
                          {text: 'Молор-Эрдэнэ', value: 'Молор-Эрдэнэ'},
                          {text: 'Болортуяа.ж', value: 'Болортуяа.ж'}]" :filter-method="filterHandler">
              </el-table-column>
              <el-table-column prop="student_time" label="Суух цаг" width="100px">
                <template slot-scope="scope">
                  <el-tag type="primary" size="mini" v-if="scope.row.remaining_time <= 20">
                    {{ scope.row.student_time }}
                    </el-tag>
                    <el-tag type="warning" size="mini" v-else-if="scope.row.student_time == 360">
                    жил
                    </el-tag>
                    <el-tag type="info" size="mini" v-else>
                      {{ scope.row.student_time }}
                    </el-tag>
                </template>
        </el-table-column>
        <el-table-column prop="remaining_time" label="Үлдсэн цаг" width="100px">
          <template slot-scope="scope">
            <el-tag type="primary" size="mini" v-if="scope.row.remaining_time <= 20">
              {{ scope.row.remaining_time }}
              </el-tag>
              <el-tag type="warning" size="mini" v-else-if="scope.row.student_time == 360">
              жил
              </el-tag>
              <el-tag type="info" size="mini" v-else>
                {{ scope.row.remaining_time }}
              </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="start_date" label="Эхлэх" width="100px">
        </el-table-column>
        <el-table-column prop="end_date" label="Дуусах" width="100px">
        </el-table-column>
        <el-table-column prop="eelj" label="Ээлж" width="100px">
        </el-table-column>
              <!-- <el-table-column prop="student_note" label="Тэмдэглэл" width="150px">
                <template slot-scope="scope" v-if="scope.row.student_note">
                  {{ scope.row.student_note.description }}
                </template>
              </el-table-column> -->
              <el-table-column :label="item" width="150px" v-for="item, index in getWorkingDays()" :key="index">
                <template slot-scope="scope">
                  <div v-for="i, index in scope.row.view_to_attendance" :key="index">
                    <template v-if="i.created_at.split('T')[0] === item">
                      <el-tag size="mini" v-if="i.status_id == 100" type="success">И</el-tag>
                      <el-tag size="mini" v-if="i.status_id == 101" type="danger">Т</el-tag>
                      <el-tag size="mini" v-if="i.status_id == 102" type="primary">Ч</el-tag>
                      <el-tag size="mini" v-if="i.status_id == 103" type="warning">Ө</el-tag>
                    </template>
                    <template v-if="i.created_at.split('T')[0] === item">
                      <el-tag v-if="i.status_id == 100" size="mini" type="warning">{{ i.percent }}</el-tag>
                    </template>
                    <template v-if="i.created_at.split('T')[0] === item">
                      <el-tag v-if="i.status_id == 100" size="mini" type="primary">{{ i.late_minute }}</el-tag>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-dialog title="Сурагчийн тэмдэглэл" :visible.sync="this.$root.studentNoteDialog" :before-close="handleCloseNote" width="80%">
          <div class="m-b-20" style="color: grey">
            <span>Овог: {{ suragchiin_ovog }}</span><br>
            <span>Нэр: {{ suragchiin_ner }}</span>
          </div>
          <el-form>
            <el-form-item v-model="studentCreateNote">
              <el-input type="textarea" v-model="studentCreateNote.description" placeholder="Тэмдэглэл оруулах"></el-input>
            </el-form-item>
            <el-button type="success" size="mini" @click="saveStuentNote(studentCreateNote)">Хадгалах</el-button>
          </el-form>
          <el-table :data="student_note" class="text_size p-t-10">
            <el-table-column label="№" type="index"></el-table-column>
            <el-table-column label="Тэмдэглэл" prop="description"></el-table-column>
            <el-table-column label="Бичсэн хүн">
              <template slot-scope="scope">{{ scope.row.teacher.name }}</template>
            </el-table-column>
            <el-table-column label="Огноо">
              <template slot-scope="scope">
                {{ scope.row.created_at.split('T')[0] }}
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
    </el-container>
  </template>

<script>
import moment from 'moment'
import services from '@/helpers/services.js'

export default {
  name: 'AttendanceView',
  data () {
    return {
      loading: true,
      studentList: [],
      eelj: 1,
      days: 30,
      lesson_name: 'mat',
      teacher_name: '',
      options: [{
        value: 1,
        label: '1-р ээлж'
      }, {
        value: 2,
        label: '2-р ээлж'
      }, {
        value: 3,
        label: '3-р ээлж'
      }, {
        value: 4,
        label: '4-р ээлж'
      }, {
        value: 5,
        label: '5-р ээлж'
      }, {
        value: 6,
        label: '6-р ээлж'
      }, {
        value: 7,
        label: '7-р ээлж'
      }],
      lessons: [{
        value: 'eng',
        label: 'Англи хэл'
      }, {
        value: 'mat',
        label: 'Математик'
      }],
      optionsTeacher: [{
        value: 'Болортуяа',
        label: 'Болортуяа'
      }, {
        value: 'Алтанзаяа',
        label: 'Алтанзаяа'
      }, {
        value: 'Молор-Эрдэнэ',
        label: 'Молор-Эрдэнэ'
      }, {
        value: 'Болортуяа.ж',
        label: 'Болортуяа.ж'
      }],
      optionsLesson: [{
        value: 'mat',
        label: 'Математик'
      }, {
        value: 'eng',
        label: 'Англи хэл'
      }],
      suragchiin_id: null,
      student_note: {
        teacher: {}
      },
      studentCreateNote: {
        description: ''
      },
      suragchiin_ovog: '',
      suragchiin_ner: ''
    }
  },
  created () {
    // this.getAttendanceList()
    this.viewAttendanceTeacherApp()
  },
  methods: {
    getWorkingDays () {
      const workingDays = []
      const today = moment()

      for (let i = 0; i <= this.days; i++) {
        const date = today.clone().subtract(i, 'days')
        if (date.isoWeekday() < 6) {
          workingDays.push(date.format('YYYY-MM-DD'))
        }
      }
      return workingDays.reverse()
    },
    viewAttendanceTeacherApp () {
      this.$root.fullscreenLoading = true
      const payload = {
        eelj: this.eelj,
        lesson_name: this.lesson_name
      }
      services.viewAttendanceTeacherApp(payload)
        .then((response) => {
          this.studentList = response.result
          this.$root.fullscreenLoading = false
        })
    },
    getStudentNote (data) {
      this.$root.fullscreenLoading = true
      this.$root.studentNoteDialog = true
      this.suragchiin_id = data.id
      this.suragchiin_ovog = data.name
      this.suragchiin_ner = data.last_name
      const payload = {
        student_id: data.id
      }
      services.getStudentNote(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.student_note = response.result
            this.$root.fullscreenLoading = false
          }
        })
    },
    handleCloseNote () {
      this.$root.studentNoteDialog = false
    },
    saveStuentNote (data) {
      this.$root.fullscreenLoading = true
      const payload = {
        student_id: this.suragchiin_id,
        valueDesc: data.description
      }
      services.createStudentNote(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.$root.fullscreenLoading = false
            location.reload()
          }
        })
    }
  }
}
</script>

<style>
.text_size{
  font-size: 10px !important;
}
</style>
